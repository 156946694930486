import { useState } from 'react';
import classNames from 'classnames';
import GlobalCart from '@fff-web/fff-cart';
import { getEnv } from '../../../../../../../environment';
import useInView from '../../../../../../hooks/useInView';
import HamburgerMenuIcon from '../../../../../HamburgerMenuIcon';
import { getLogoImage } from '../../../../../../utils/getLogoImage';
import MobileMenu from '../../../MobileMenu/MobileMenu';
import * as styles from './LeftMenu.module.scss';


type Props = {
  showCart?: boolean;
  stickyCartPage?: boolean;
  isLoginPage?: boolean;
  isPublicShop?: boolean;
};

export const LeftMenu = ({ showCart, stickyCartPage, isLoginPage, isPublicShop }: Props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const toggleMenu = () => setIsOpenMenu(!isOpenMenu);

  const env = getEnv();

  const { isInView, elementRef: headerRef } = useInView();

  const stickyCart = !isInView && stickyCartPage;

  return (
    <div className={styles.leftMenuContainer} data-testid="left-menu" ref={headerRef}>
      <div className={styles.headerMenuContainer}>
        <HamburgerMenuIcon isOpen={isOpenMenu} onClick={toggleMenu} />
        <div className={styles.logoContainer}>
          <a className={styles.fffLogo} href={env.REACT_APP_BASE_URL} data-testid="logo-link">
            <img src={getLogoImage(env?.name)} alt="fabfitfun logo" />
          </a>
        </div>
        <div className={classNames(styles.rightContainer, { [styles.hidden]: isPublicShop })}></div>
        {showCart && (
          <div className={classNames(styles.globalCart, { sticky_cart: stickyCart })}>
            <GlobalCart env={env.name} />
          </div>
        )}
      </div>
      <div className={styles.mobileMenuContainer}>
        <MobileMenu isOpenMenu={isOpenMenu} isLoginPage={isLoginPage} isPublicShop={isPublicShop} />
      </div>
    </div>
  );
};
