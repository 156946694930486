import { memo, StrictMode } from 'react';
import { bool, string } from 'prop-types';
import { getEnv } from '../environment';
import { Banner } from './components/Banners/Banner';
import NMFHeader from './components/Header/NMFHeader';
import GlobalNavInternalContext from './context/GlobalNavInternalContext';
import OptimizelyProvider from './context/OptimizelyProvider';
import { useBootstrapLD } from './hooks/useBootstrapLD';
import { useNmfRedirect } from './hooks/useNmfRedirect';
import { useNmfTimer } from './hooks/useNmfTimer';

type NewMemberNavTemplateProps = {
  env?: string;
  step: string;
  isEditCampaignOpen: boolean;
};

const NewMemberNavTemplate = ({
  env: envName = '',
  step,
  isEditCampaignOpen,
}: NewMemberNavTemplateProps) => {
  const env = getEnv(envName);
  const {
    userId,
    endTime,
    lastVisitedCustomizeQuestionId,
    timerType,
    timerSource,
    newMemberState,
    loading: timerLoading,
  } = useNmfTimer(env);

  const {
    downloadMobileAppWebBannerFF,
    downloadMobileAppWebBannerReady,
    ordersCtaFF,
    ordersCtaReady,
    webRatingsAndReviewInGlobalNavDropDownFF,
    webRatingsAndReviewInGlobalNavDropDownReady,
    ccToastNotificationFF,
    ccToastNotificationReady,
  } = useBootstrapLD(env.name, userId);

  const contextValue = {
    env,
    flags: {
      downloadMobileAppWebBannerFF,
      downloadMobileAppWebBannerReady,
      ordersCtaFF,
      ordersCtaReady,
      webRatingsAndReviewInGlobalNavDropDownFF,
      webRatingsAndReviewInGlobalNavDropDownReady,
      ccToastNotificationFF,
      ccToastNotificationReady,
    },
  };

  useNmfRedirect(newMemberState, lastVisitedCustomizeQuestionId, env.REACT_APP_BASE_URL, timerType);

  if (timerLoading || !timerSource) {
    return null;
  }

  return (
    <StrictMode>
      <OptimizelyProvider>
        <GlobalNavInternalContext.Provider value={contextValue}>
          <Banner />
          <NMFHeader
            step={step}
            isEditCampaignOpen={isEditCampaignOpen}
            endTime={endTime}
            timerSource={timerSource}
          />
        </GlobalNavInternalContext.Provider>
      </OptimizelyProvider>
    </StrictMode>
  );
};

NewMemberNavTemplate.propTypes = {
  env: string.isRequired,
  step: string.isRequired,
  isEditCampaignOpen: bool,
};

export default memo(NewMemberNavTemplate);
